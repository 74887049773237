.wrapper {
  width: 100%;
}

.accredited {
  padding: 3rem 1rem;
  background: #FAFAFA;

  .boxTxt {
    width: 100%;

    @media (min-width:700px) {
      padding: 0 1rem;
      width: 70%;
    }
    @media (min-width:1200px) {
      padding: 2rem 4rem;
      width: 60%;
    }
    @media (min-width:1600px) {
      padding: 2rem 6rem;
      width: 50%;
    }

    &H {
      font-size: 1.3rem;
      line-height: 1.4;
      font-weight: 800;
      padding: .5rem;
      color: #00C8FF;
      opacity: 0;
      transform: translateY(90px);

      @media (min-width:1200px) {
        font-size: 2rem;
        line-height: 1.2;
      }
      @media (min-width:1600px) {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    }

    &P {
      color: #303034;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 600;
      padding: .5rem;
      opacity: 0;
      transform: translateY(90px);

      @media (min-width:1200px) {
        font-size: 1.1rem;
        line-height: 1.5;
      }
      @media (min-width:1600px) {
        font-size: 1.3rem;
        line-height: 1.7;
        padding: 2rem 6rem 0 .5rem;
      }

      .pdf {
        position: relative;
        display: inline-block;
        color: #303034;
        text-decoration: none;
        z-index: 3;

        &::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background: rgba(0,200,255,.3);
          transform-origin: bottom;
          transition: .3s;
          z-index: -1;
        }

        &:hover::before {
          transform: scaleY(5) skewX(-45deg);
        }
      }
      i {
        color: rgba(0,200,255,.3);

        @media (min-width:1200px) {
          font-size: 1.3rem;
        }
        @media (min-width:1600px) {
          font-size: 1.6rem;
        }
      }
    }
  }

  .akredytacja {
    display: flex;
    flex-direction: column;

    .inputTxt {
      font-family: 'Muli', sans-serif;
      font-size: 1rem;
      font-weight: 600;
      color: #303034;
      margin: 0;
      padding-left: .5rem;
      background: #FAFAFA;
      border: none;
      border-bottom: 4px solid rgba(0,200,255,.3);
      transform: skewX(-10deg);

      &:focus {
        outline: none;
      }

      @media (min-width:700px) {
        margin: 0 .5rem 0 0 ;
      }
      @media (min-width:1200px) {
        font-size: 1.1rem;
        line-height: 1.5;
      }
      @media (min-width:1400px) {
        margin: 0 .5rem;
      }
      @media (min-width:1600px) {
        font-size: 1.3rem;
      }
    }
  }

  .pcaDiv {
    margin-top: .5rem;
    width: 6rem;
    transform: translateY(50px);
    opacity: 0;

    @media (min-width:1400px) {
      width: 8rem;
    }

    .logoPca {
      display: block;
      padding: .5rem .5rem 0;
      width: 6rem;

      @media (min-width:1400px) {
        width: 8rem;
      }
    }
  
    .logoPcaTxt {
      font-size: .9rem;
      text-align: center;
    }
  }

  

  .boxSurvey {
    width: 100%;
    padding: 2rem 0;

    @media (min-width:700px) {
      padding: 2rem 1rem;
      margin: -5rem 0 0 auto;
      width: 75%;
    }
    @media (min-width:1200px) {
      padding: 1rem 3rem;
      width: 75%;
      margin: -6rem 3rem 0 auto;
    }
    @media (min-width:1400px) {
      padding: 0 5rem 2rem;
      margin: -7rem 7rem 0 auto;
    }
    @media (min-width:1600px) {
      padding: 0 5rem 2rem;
      width: 75%;
      margin: -9rem 8rem 0 auto;
    }

    .topPanel {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: -9px -9px 12px 0 rgba(255,255,255, 1),
                    9px 9px 12px 0 rgba(0,0,0, .03);

      @media (min-width:700px) {
        grid-template-columns: 55% 1fr;
      }
      @media (min-width:1600px) {
        grid-template-columns: 60% 1fr;
      }

      &Txt {
        font-size: .9rem;
        font-weight: 800;
        color: #00C8FF;
        padding: .5rem;

        @media (min-width:700px) {
          padding: 1rem;
        }
        @media (min-width:1200px) {
          padding: 1rem 2rem;
          font-size: 1rem;
        }
        @media (min-width:1600px) {
          padding: 1.5rem 3rem;
          font-size: 1.1rem;
        }
      }
    }
  }

  .wrapperMethods {

    .methods {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 5px;
      padding: .5rem 0;
      margin: .6rem 0;
      box-shadow: inset -9px -9px 12px 0 rgba(255,255,255, 1),
                  inset 9px 9px 12px 0 rgba(0,0,0, .03),
                  -9px -9px 12px 0 rgba(255,255,255, 1),
                  9px 9px 12px 0 rgba(0,0,0, .03);
  
      @media (min-width:700px) {
        grid-template-columns: 55% 1fr;
      }
      @media (min-width:1200px) {
        margin: .8rem 0;
      }
      @media (min-width:1600px) {
        grid-template-columns: 60% 1fr;
        margin: 1rem 0;
      }
    }
  
    .methodsLeft {
      padding: .5rem;
      color: #303034;
  
      &Title {
        font-size: .8rem;
        line-height: 1.2;
        margin-bottom: .3rem;
        font-weight: 800;

        @media (min-width:1200px) {
          font-size: 1rem;
          margin-bottom: .5rem;
        }
        @media (min-width:1600px) {
          font-size: 1.1rem;
        }
      }
  
      &Txt {
        font-size: .6rem;
        line-height: 1.5;
        font-weight: 400;

        @media (min-width:1200px) {
          font-size: .7rem;
        }
        @media (min-width:1600px) {
          font-size: .9rem;
        }
  
        i {
          font-size: .4rem;

          @media (min-width:1200px) {
            margin-right: .3rem;
          }
          @media (min-width:1600px) {
            font-size: .5rem;
            
          }
        }
      }
    
      @media (min-width:700px) {
        padding: 1rem;
      }
      @media (min-width:1200px) {
        padding: 1rem 2rem;
      }
      @media (min-width:1600px) {
        padding: 1.5rem 3rem;
      }
    }
    
    .methodsRights {
      padding: .5rem;
      color: #303034;
      border-left: 1px solid rgba(48,48,52, .2);
    
      @media (min-width:700px) {
        padding: 1rem;
      }
      @media (min-width:1200px) {
        padding: 1rem 2rem;
        font-size: 1rem;
      }
      @media (min-width:1600px) {
        padding: 1.5rem 3rem;
      }

      &Txt {
        font-size: .8rem;
        line-height: 1.2;

        @media (min-width:1600px) {
          font-size: .9rem;
        }
      }
    }
  }


}



