@import url('https://fonts.googleapis.com/css?family=Palanquin+Dark:400,500,600,700&display=swap&subset=latin-ext');

@import url('https://fonts.googleapis.com/css?family=Muli:200,300,400,500,700,800,900&display=swap&subset=latin-ext');


*, *::before, *::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  /* font-family: 'Palanquin Dark', sans-serif; */
  font-family: 'Muli', sans-serif;
  margin: 0;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #00C8FF;
}