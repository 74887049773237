.wrapper {
  width: 100%;
  background: #fff;
  padding: 3rem 1rem;
  z-index: 4;

  @media (min-width:700px) {
    padding: 5rem 3rem 0 0;
  }
  @media (min-width:1000px) {
    padding: 6rem 4rem 0 0;
  }
  @media (min-width:1200px) {
    min-height: 100vh;
    padding: 6rem 4rem 0 0;
  }
}

.boxText {
  width: 100%;

  @media (min-width:700px) {
    width: 65%;
    margin: 0 0 0 auto;
  }
  @media (min-width:1000px) {
    width: 60%;
  }
  @media (min-width:1200px) {
    padding: 1rem;
    width: 50%;
  }
  @media (min-width:1600px) {
    padding: 1rem 3rem 0 0;
    transform: translateX(-5rem);
  }

  .h2 {
    font-size: 1.3rem;
    line-height: 1.4;
    padding: .5rem;
    color: #00C8FF;
    font-weight: 800;
    opacity: 0;
    transform: translateY(90px);

    @media (min-width:1200px) {
      font-size: 2rem;
      line-height: 1.2;
    }
    @media (min-width:1600px) {
      font-size: 3rem;
      line-height: 1.2;
    }
  }

  .txt {
    font-size: 1rem;
    line-height: 1.4;
    color: #303034;
    font-weight: 600;
    padding: .5rem;
    opacity: 0;
    transform: translateY(90px);

    @media (min-width:1200px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
    @media (min-width:1600px) {
      font-size: 1.3rem;
      line-height: 1.7;
      padding: 2rem 6rem 0 .5rem;
    }
  }
}

.boxImg {
  width: 100%;
  display: grid;
  grid-template-columns: 40% 1fr;
  padding: 2rem .5rem 0;

  @media (min-width:700px) {
    width: 70%;
    padding: 3rem .5rem 0 0;
  }
  @media (min-width:1000px) {
    width: 60%;
    padding: 4rem .5rem 0 0;
  }
  @media (min-width:1600px) {
    width: 55%;
    padding: 2.5rem 0 0 0;
  }

  .leftImg {

    @media (min-width:1600px) {
      height: 50vh;
    }

    .img01 {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: translateX(-100%);
      opacity: 0;
      z-index: 2;
    }
  }

  .rightImg {

    @media (min-width:1600px) {
      height: 50vh;
    }

    .img02 {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transform: translateX(-100%);
      opacity: 0;
      z-index: 1;
    }
  }
}