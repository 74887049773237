.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 5px;
  padding: .5rem 0;
  margin: .6rem 0;
  box-shadow: inset -9px -9px 12px 0 rgba(255,255,255, 1),
              inset 9px 9px 12px 0 rgba(0,0,0, .03),
              -9px -9px 12px 0 rgba(255,255,255, 1),
              9px 9px 12px 0 rgba(0,0,0, .03);

  @media (min-width:700px) {
    grid-template-columns: 55% 1fr;
  }
  @media (min-width:1200px) {
    margin: .8rem 0;
  }
  @media (min-width:1600px) {
    margin: 1rem 0;
  }
}

.leftTxt {
  font-size: .8rem;
  font-weight: 800;
  padding: .5rem;
  color: #303034;

  @media (min-width:700px) {
    padding: 1rem;
  }
  @media (min-width:1200px) {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
  @media (min-width:1600px) {
    padding: 1.5rem 3rem;
    font-size: 1rem;
  }
}

.rightTxt {
  font-size: .8rem;
  padding: .5rem;
  color: #303034;
  border-left: 1px solid rgba(48,48,52, .2);

  @media (min-width:700px) {
    padding: 1rem;
  }
  @media (min-width:1200px) {
    padding: 1rem 2rem;
    font-size: 1rem;
  }
  @media (min-width:1600px) {
    padding: 1.5rem 3rem;
    font-size: 1rem;
  }
}