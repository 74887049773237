.wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background: #FFF;

  @media (min-width:1200px) {
    min-height: 100vh;
  }
}

.secondWrapper {
  opacity: 0;
  background-image: linear-gradient(90deg, rgba(165, 165, 165, 0.03) 0%, rgba(165, 165, 165, 0.03) 8%,rgba(235, 235, 235, 0.03) 8%, rgba(235, 235, 235, 0.03) 9%,rgba(7, 7, 7, 0.03) 9%, rgba(7, 7, 7, 0.03) 14%,rgba(212, 212, 212, 0.03) 14%, rgba(212, 212, 212, 0.03) 17%,rgba(219, 219, 219, 0.03) 17%, rgba(219, 219, 219, 0.03) 95%,rgba(86, 86, 86, 0.03) 95%, rgba(86, 86, 86, 0.03) 100%),linear-gradient(67.5deg, rgba(80, 80, 80, 0.03) 0%, rgba(80, 80, 80, 0.03) 11%,rgba(138, 138, 138, 0.03) 11%, rgba(138, 138, 138, 0.03) 17%,rgba(122, 122, 122, 0.03) 17%, rgba(122, 122, 122, 0.03) 24%,rgba(166, 166, 166, 0.03) 24%, rgba(166, 166, 166, 0.03) 27%,rgba(245, 245, 245, 0.03) 27%, rgba(245, 245, 245, 0.03) 89%,rgba(88, 88, 88, 0.03) 89%, rgba(88, 88, 88, 0.03) 100%),linear-gradient(67.5deg, rgba(244, 244, 244, 0.03) 0%, rgba(244, 244, 244, 0.03) 4%,rgba(16, 16, 16, 0.03) 4%, rgba(16, 16, 16, 0.03) 10%,rgba(157, 157, 157, 0.03) 10%, rgba(157, 157, 157, 0.03) 20%,rgba(212, 212, 212, 0.03) 20%, rgba(212, 212, 212, 0.03) 83%,rgba(5, 5, 5, 0.03) 83%, rgba(5, 5, 5, 0.03) 84%,rgba(237, 237, 237, 0.03) 84%, rgba(237, 237, 237, 0.03) 100%),linear-gradient(90deg, #FFF,#FFF);
  
  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 60% 1fr;
    height: 60vh;
    align-items: center;
    margin: 1rem;
  }

  @media (min-width:1200px) {
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 4rem);
    margin: 2rem;
  }
}

.title {
  padding: 4rem 1rem 1rem;
  display: inline-block;

  @media (min-width:700px) {
    padding: 4rem 2rem 1rem;
  }
  @media (min-width:1200px) {
    padding: 1rem 4.5rem;
  }
  @media (min-width:1600px) {
    padding: 1rem 5rem;
  }

    &Main {
      font-size: 2.2rem;
      font-weight: 800;
      line-height: 1.1;
      color: #00C8FF;
      transform: translateX(-50px);
      opacity: 0;

      @media (min-width:1200px) {
        font-size: 3.5rem;
      }
      @media (min-width:1600px) {
        font-size: 4rem;
      }

      &::first-letter {
        font-size: 2.4rem;
        font-weight: 900;
        color: transparent;
        background: #00C8FF;
        background-clip: text;
        text-shadow: 0px 3px 3px rgba(255,255,255,0.5);

        @media (min-width:1200px) {
          font-size: 4rem;
        }
        @media (min-width:1600px) {
          font-size: 4.5rem;
        }
      }
    }

    .contenerTxt {
      margin: .7rem;

      @media (min-width:700px) {
        margin: .7rem 2rem .7rem .7rem;
      }
      @media (min-width:1200px) {
        margin: 1.5rem 4rem .7rem .7rem;
      }
      @media (min-width:1600px) {
        margin: 2rem 9rem 1rem 1rem;
      }
    }

    &Text {
      font-size: .9rem;
      font-weight: 400;
      padding: .3rem 0;
      color: #303034;
      transform: translateY(50px);
      opacity: 0;

      @media (min-width:1200px) {
        font-size: 1.1rem;
      }
      @media (min-width:1600px) {
        font-size: 1.2rem;
      }
    }
  }

.headerImg {
  position: relative;
  width: 110%;
  margin-top: 2rem;

  @media (min-width:1020px) {
    width: 100%;
    margin: 5% auto 0;
    border-radius: 5px;
    margin-top: 0;
  }
  @media (min-width:1200px) {
    transform: translateX(3rem);
  }
  // @media (min-width:1600px) {
  //   width: 100%;
  //   transform: translateX(3rem);
  // }

  .bgImg {
    width: 100%;
    display: block;
    transform: scaleX(0);
    transform-origin: right;
    opacity: 0;

    @media (min-width:700px) {
      width: 110%;
    }
    @media (min-width:1200px) {
      width: 100%;
    }
  }
}
