.wrapper {
  width: 100%;
  background: #303034;
}

.box {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 3rem 1rem;

  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 6rem 1rem;
  }
  @media (min-width:1000px) {
    display: grid;
    grid-template-columns: 45% 1fr;
    justify-content: center;
    align-items: center;
    padding: 6rem 2rem;
  }
  @media (min-width:1400px) {
    padding: 8rem 2rem;
  }
  @media (min-width:1600px) {
    display: grid;
    grid-template-columns: 40% 1fr;
    max-width: 1300px;
  }
}

.boxText {

  .txt {
    font-size: 1rem;
    line-height: 1.4;
    color: #fff;
    font-weight: 600;
    padding: .5rem;
    opacity: 0;
    transform: translateY(90px);

    @media (min-width:1000px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
    @media (min-width:1400px) {
      font-size: 1.2rem;
      line-height: 1.6;
    }
    @media (min-width:1600px) {
      font-size: 1.3rem;
      line-height: 1.7;
    }
  }
}

  .pdf {
    position: relative;
    display: inline-block;
    color: #fff;
    text-decoration: none;
    z-index: 3;
    font-size: 1.1rem;

    @media (min-width:1000px) {
      font-size: 1.2rem;
    }
    @media (min-width:1400px) {
      font-size: 1.3rem;
    }
    @media (min-width:1600px) {
      font-size: 1.4rem;
    }

    &::before {
      position: absolute;
      content: '';
      left: 0;
      bottom: 0;
      width: 100%;
      height: 5px;
      background: rgba(0,200,255,.3);
      transform-origin: bottom;
      transition: .3s;
      z-index: -1;
    }

    &:hover::before {
      transform: scaleY(5) skewX(-45deg);
    }
  }

.boxImage {
  position: relative;
  width: 100%;

  .boxImage1 {
    display: block;
    width: 100%;
  }

  .boxImage2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: shining 3s infinite linear;
  }
  .boxImage3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    animation: shining 3s infinite 1s linear;
  }
}

@keyframes shining {
  0% {
    opacity: 0;
    filter: brightness(70%);
    transform: scale(.98);
  }
  50% {
    opacity: 1;
    filter: brightness(110%);
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    filter: brightness(70%);
    transform: scale(.98);
  }
}
