.wrapper {
  width: 100%;
  width: 100%;
  // padding: 3rem 1rem;

  @media (min-width:1600px) {
    // padding: 6rem 1rem;
  }
}

.mineBox {
  width: 100%;
  height: 100%;
  background: #FAFAFA;
  padding: 3rem 1rem;
  transform: scaleX(0);
  transform-origin: right;

  @media (min-width:1600px) {
    padding: 6rem 1rem;
  }
}

.titleH3 {
  font-size: 1.2rem;
  line-height: 1.4;
  color: #00C8FF;
  font-weight: 800;
  text-align: center;
  grid-area: boxTitle;
  opacity: 0;
  transform: translateY(90px);

  @media (min-width:700px) {
    padding: 1rem 7rem;
  }
  @media (min-width:1200px) {
    font-size: 1.7rem;
    line-height: 1.2;
    padding: 1rem 12rem;
  }
  @media (min-width:1600px) {
    font-size: 2.3rem;
    line-height: 1.2;
    padding: 1rem 12rem;
  }
}

.box {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem 0 0;

  @media (min-width:700px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
      "left right";
  }
  @media (min-width:1000px) {
    padding: 2rem;
  }
  @media (min-width:1600px) {
    max-width: 1300px;
  }

  .boxBLeft {
    grid-area: left;
  }

  .boxBRight {
    grid-area: right;
  }

  .boxTxt {
    font-size: 1rem;
    line-height: 1.4;
    text-align: center;
    color: #303034;
    font-weight: 600;
    padding: .3rem .5rem;
    opacity: 0;
    transform: translateY(70px);

    @media (min-width:1200px) {
      font-size: 1.1rem;
      line-height: 1.5;
    }
    @media (min-width:1600px) {
      font-size: 1.3rem;
      line-height: 1.7;
    }
  }
}