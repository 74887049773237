.wrapper {
  width: 100%;
  background: #303034;
  padding: 3rem 1rem;

  @media (min-width:700px) {
    padding: 3rem;
  }

  @media (min-width:1200px) {
    display: grid;
    padding: 4rem 10rem;
    grid-template-columns: 1fr 1fr;
    align-items: baseline;
  }
  @media (min-width:1600px) {
    padding: 6rem 15rem;
  }
}

.contact {
  padding: 2rem 0;

  @media (min-width:1600px) {
    padding: 2rem 10rem;
  }

  &Title {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    color: #fff;

    @media (min-width:1600px) {
      margin-bottom: 1.5rem;
      font-size: 2.1rem;
    }
  }

  &Mail {
    display: inline-block;
    align-items: center;
    padding: .5rem;
    text-decoration: none;
    color: #00C8FF;
    transition: .3s;

    &:hover {
      box-shadow: inset -5px -5px 5px 0 rgba(254,254,254, .03),
                  inset 5px 5px 5px 0 rgba(0,0,0, .08),
                  -5px -5px 5px 0 rgba(254,254,254, .03),
                  5px 5px 5px 0 rgba(0,0,0, .08);
    }

    @media (min-width:1600px) {
      font-size: 1.2rem;
    }
  }
}

.menuOffer {
  padding: 2rem 0;

  @media (min-width:1600px) {
    padding: 2rem 10rem 2rem 0;
  }

  &Title {
    margin-bottom: .5rem;
    font-size: 1.2rem;
    color: #fff;

    @media (min-width:1600px) {
      margin-bottom: 1.5rem;
      font-size: 2.1rem;
    }
  }

  &Ul {

    &Item {
      list-style: none;
      padding: .5rem 0;
      
      &Link {
        align-items: center;
        text-decoration: none;
        padding: .5rem;
        color: #00C8FF;
        font-weight: 800;
        transition: .3s;

        &:hover {
          box-shadow: inset -5px -5px 5px 0 rgba(254,254,254, .03),
                      inset 5px 5px 5px 0 rgba(0,0,0, .08),
                      -5px -5px 5px 0 rgba(254,254,254, .03),
                      5px 5px 5px 0 rgba(0,0,0, .08);
        }

        @media (min-width:1600px) {
          font-size: 1.2rem;
        }

        .navSpan {
          font-weight: 300;
          margin-right: 4px;
        }
      }
    }
  }
}