.menu {

  .burger {
    position: fixed;
    top: 0;
    left: 0;
    padding: .5rem .8rem;
    margin: .4rem;
    cursor: pointer;
    z-index: 10;
    color: #141414;
    font-weight: 600;
    border-radius: 5px;
    box-shadow: inset -10px -10px 10px 0 rgba(254,254,254, 0),
                inset 10px 10px 10px 0 rgba(0,0,0, .00),
                -10px -10px 10px 0 rgba(254,254,254, 0),
                10px 10px 10px 0 rgba(0,0,0, .00);
    background: transparent;
    opacity: 0;

    @media (min-width:700px) {
      margin: 1rem;
    }
    @media (min-width:1200px) {
      margin: 3rem 4rem;
    }

    .up {
      transition: .3s;
    }
    .down {
      transition: .3s;
    }
  }

  .burger span:hover .up {
    color: #00C8FF;
    padding: 0 1px;
  }
  .burger span:hover .down {
    color: #00C8FF;
    padding: 0 1px;
  }
}

.whiteDiv {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #00C8FF;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  clip-path: polygon(0 0, 100% 0, 100% 95%, 0% 100%);
  z-index: 5;
}

.wrapper {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: 1fr auto;
  justify-content: center;
  align-items: center;
  z-index: 7;
  transform: translateY(-100%);
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  background-image: linear-gradient(90deg, rgba(0, 0, 0,0.03) 0%, rgba(0, 0, 0,0.03) 61%,rgba(232, 232, 232,0.03) 61%, rgba(232, 232, 232,0.03) 75%,rgba(57, 57, 57,0.03) 75%, rgba(57, 57, 57,0.03) 100%),linear-gradient(0deg, rgba(234, 234, 234,0.03) 0%, rgba(234, 234, 234,0.03) 53%,rgba(23, 23, 23,0.03) 53%, rgba(23, 23, 23,0.03) 78%,rgba(226, 226, 226,0.03) 78%, rgba(226, 226, 226,0.03) 100%),linear-gradient(90deg, rgb(255,255,255),rgb(255,255,255));

  @media (min-width:1200px) {
    justify-content: start;
    padding-left: 20rem;
  }

  &Ul {
    opacity: 0;
    width: 100%;

    @media (min-width:1600px) {
      margin-bottom: .8rem;
    }
  }

  .navItem {
    padding: .3rem;
    list-style: none;
    font-size: 1.3rem;
    font-weight: 900;
    letter-spacing: 2px;
    opacity: 0;
    transform: translateY(-100px);

    @media (min-width:700px) {
      font-size: 1.8rem;
      padding: .5rem;
    }
    @media (min-width:1200px) {
      font-size: 2.5rem;
      padding: .8rem;
    }
    @media (min-width:1600px) {
      font-size: 2.8rem;
      padding: 1rem;
    }

    .navItemLink {
      position: relative;
      text-decoration: none;
      color: #575D61;
      padding: .5rem;
      border-radius: 5px;
      transition: .3s;

      @media (min-width:1200px) {
        padding: .6rem 1rem;
      }
      @media (min-width:1600px) {
        padding: .6rem 1rem;
      }

      &:hover {
        color: #00C8FF;
        box-shadow: inset -10px -10px 10px 0 rgba(254,254,254, 1),
                    inset 10px 10px 10px 0 rgba(0,0,0, .03),
                    -10px -10px 10px 0 rgba(254,254,254, 1),
                    10px 10px 10px 0 rgba(0,0,0, .03);
      }
    }

    .navItemLinkActive {
      position: relative;
      font-size: 1.4rem;
      text-decoration: none;
      color: #00C8FF;

      @media (min-width:700px) {
        font-size: 1.9rem;
        padding: .5rem;
      }
      @media (min-width:1200px) {
        font-size: 2.8rem;
        padding: .6rem 1rem;
      }
      @media (min-width:1600px) {
        font-size: 3rem;
        padding: .6rem 1rem;
      }
    }

    .navSpan {
      font-weight: 300;
    }
  }
}

.copy {
  padding: .5rem;
  opacity: 0;

  @media (min-width:1200px) {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
  }

  &Txt {
    font-size: .7rem;
    padding: .2rem;

    @media (min-width:1200px) {
      font-size: .8rem;
    }
  }
}