.wrapper {
  width: 100%;
}

.nonAccredited {
  padding: 3rem 1rem;
  background: #FAFAFA;

  .boxTxt {
    width: 100%;

    @media (min-width:700px) {
      padding: 0 1rem;
      width: 70%;
    }
    @media (min-width:1200px) {
      padding: 2rem 4rem;
      width: 60%;
    }
    @media (min-width:1600px) {
      padding: 2rem 6rem;
      width: 50%;
    }

    &H {
      font-size: 1.3rem;
      line-height: 1.4;
      font-weight: 800;
      padding: .5rem;
      color: #00C8FF;
      opacity: 0;
      transform: translateY(90px);

      @media (min-width:1200px) {
        font-size: 2rem;
        line-height: 1.2;
      }
      @media (min-width:1600px) {
        font-size: 2.5rem;
        line-height: 1.2;
      }
    }

    &P {
      color: #303034;
      font-size: 1rem;
      line-height: 1.4;
      font-weight: 600;
      padding: .5rem;
      opacity: 0;
      transform: translateY(90px);

      @media (min-width:1200px) {
        font-size: 1.1rem;
        line-height: 1.5;
      }
      @media (min-width:1600px) {
        font-size: 1.3rem;
        line-height: 1.7;
        padding: 2rem 6rem 0 .5rem;
      }

      .pdf {
        position: relative;
        display: inline-block;
        color: #303034;
        text-decoration: none;
        z-index: 3;

        &::before {
          position: absolute;
          content: '';
          left: 0;
          bottom: 0;
          width: 100%;
          height: 5px;
          background: rgba(0,200,255,.3);
          transform-origin: bottom;
          transition: .3s;
          z-index: -1;
        }

        &:hover::before {
          transform: scaleY(5) skewX(-45deg);
        }
      }
    }
  }

  .boxSurvey {
    width: 100%;
    padding: 2rem 0;

    @media (min-width:700px) {
      padding: 2rem 1rem;
      margin: 0 0 0 auto;
      width: 75%;
    }
    @media (min-width:1200px) {
      padding: 1rem 3rem;
      width: 70%;
      margin: -3rem 3rem 0 auto;
    }
    @media (min-width:1400px) {
      padding: 0 5rem 2rem;
      margin: -3rem 8rem 0 auto;
    }
    @media (min-width:1600px) {
      padding: 0 5rem 2rem;
      width: 65%;
      margin: -2rem 10rem 0 auto;
    }

    .topPanel {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      text-align: center;
      box-shadow: -9px -9px 12px 0 rgba(255,255,255, 1),
                    9px 9px 12px 0 rgba(0,0,0, .03);

      @media (min-width:700px) {
        grid-template-columns: 55% 1fr;
      }

      &Txt {
        font-size: .9rem;
        font-weight: 800;
        color: #00C8FF;
        padding: .5rem;

        @media (min-width:700px) {
          padding: 1rem;
        }
        @media (min-width:1200px) {
          padding: 1rem 2rem;
          font-size: 1rem;
        }
        @media (min-width:1600px) {
          padding: 1.5rem 3rem;
          font-size: 1.1rem;
        }
      }
    }
  }
}