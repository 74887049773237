.wrapper {
  width: 100%;
  // min-height: 100vh;
}

.header {
  // min-height: 100vh;
  background-color: #FFFFFF;
  // background-image: repeating-linear-gradient(90deg, hsla(298,16%,68%,0.06) 0px, hsla(298,16%,68%,0.06) 1px,transparent 1px, transparent 21px,hsla(298,16%,68%,0.06) 21px, hsla(298,16%,68%,0.06) 22px,transparent 22px, transparent 72px),repeating-linear-gradient(0deg, hsla(298,16%,68%,0.06) 0px, hsla(298,16%,68%,0.06) 1px,transparent 1px, transparent 21px,hsla(298,16%,68%,0.06) 21px, hsla(298,16%,68%,0.06) 22px,transparent 22px, transparent 72px),repeating-linear-gradient(135deg, hsla(298,16%,68%,0.06) 0px, hsla(298,16%,68%,0.06) 1px,transparent 1px, transparent 21px,hsla(298,16%,68%,0.06) 21px, hsla(298,16%,68%,0.06) 22px,transparent 22px, transparent 72px),linear-gradient(90deg, hsl(275,3%,97%),hsl(275,3%,97%));
  opacity: 0;

  @media (min-width:700px) {
    margin: 1rem;
  }
  @media (min-width:1200px) {
    margin: 2rem;
  // height: calc(100vh - 4rem);
  }
}

.boxtitle {
  height: 15vh;
  padding: 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  // @media (min-width:700px) {
  //   height: 40vh;
  // }
  // @media (min-width:1200px) {
  //   height: 45vh;
  // }

  &Title {
    font-size: 2.2rem;
    font-weight: 800;
    line-height: 1.1;
    color: #00C8FF;
    transform: translateY(-60px);
    opacity: 0;
    text-align: center;

    @media (min-width:1200px) {
      font-size: 3.5rem;
    }
    @media (min-width:1600px) {
      font-size: 4rem;
    }
  }
}

.boximage {
  height: 30vh;

  @media (min-width:1200px) {
    height: calc(55vh - 4rem);
  }

  &Img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scaleX(0);
    transform-origin: right;

    @media (min-width:700px) {
      margin-left: -2rem;
      width: calc(100% + 4rem);
    }
    @media (min-width:1200px) {
      margin-left: 0rem;
      width: calc(100% + 2rem);
    }
  }
}