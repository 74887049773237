.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.errorImg {
  width: 15rem;
  display: block;
}

.errorTitle {
  font-size: 5rem;
  line-height: 1.2;
  font-weight: 900;
  color: #00C8FF;
}

.errorText {
  font-size: 1.2rem;
  font-weight: lighter;
}