.wrapper {
  width: 100%;
  overflow: hidden;
}

.teleaddress {
  padding: 3rem 1rem;

  @media (min-width:700px) {
    max-width: 600px;
    margin: 0 auto;
  }
  @media (min-width:1200px) {
    padding: 7rem 1rem;
    max-width: 650px;
  }
  @media (min-width:1600px) {
    padding: 7rem 1rem;
    max-width: 800px;
  }

  .address {
    padding: 1.5rem 0;

    @media (min-width:700px) {
      width: 70%;
    }
    @media (min-width:1600px) {
      padding: 2rem 0;
    }

    &Title {
      font-size: 1.5rem;
      font-weight: 800;
      line-height: 1.1;
      color: #303034;
      margin-bottom: 1rem;

      @media (min-width:1200px) {
        font-size: 2rem;
      }
      @media (min-width:1600px) {
        font-size: 2.5rem;
      }
    }

    &Name {
      font-size: 1.2rem;
      padding: .2rem 0;
      font-weight: 600;
      line-height: 1.1;
      color: #00C8FF;
      
      @media (min-width:1600px) {
        font-size: 1.5rem;
        margin-bottom: .5rem;
      }
    }

    &Txt {
      font-size: 1.1rem;
      padding: .2rem 0;
      font-weight: 600;

      &Src {
        font-size: 1rem;
        font-weight: 300;
        padding: .6rem 0;

        @media (min-width:1600px) {
          padding: 1rem 0 .6rem;
        }
      }
    }

    &Link {
      text-decoration: none;
      color: #00C8FF;
      font-size: 1.1rem;
      padding: .1rem 0;
      font-weight: 600;

      &:hover .iconSpan i {
        box-shadow: -5px -5px 5px 0 rgba(254,254,254, 1),
                    5px 5px 5px 0 rgba(0,0,0, .05);
      }
    }
  }

  .map {
    width: 100%;

    &Google {
      display: block;
      width: 100%;
      height: 40vh;

      @media (min-width:700px) {
        height: 25vh;
      }
      @media (min-width:1200px) {
        height: 35vh;
      }
    }
  }
}

.addressBox {
  display: flex;
}

.iconSpan {
  text-align: center;
  margin-right: .5rem;
}